import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {
  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        {/* Add social media links here when needed */}
      </ul>
    </div>
  );
}

export default FooterSocial;