import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from '../elements/Image';
import './researchCarouselStyles.css';

const data2 = [
  {
    collageImages: ['arxiv.jpg', 'minecraft.jpg', 'rl_paper.jpg'],
    title: 'Skills from Large Language Model-guided Agents Yield Success',
    description: 'Using agented LLMs and document retrieval to simulate an reinforcement learning.',
    paperLink: 'https://www.youtube.com/watch?v=t-fKDOdZ0hk',
  },
  {
    collageImages: ['arxiv.jpg', 'dom.jpg', 'dom_paper.jpg'],
    title: 'Deep Learning Approaches for Optimized Web Accessibility',
    description: 'Correcting DOM violations and enhancing user experience with LLM design analysis.',
    paperLink: 'https://www.youtube.com/watch?v=wR9-2_YdVts',
  },
  {
    collageImages: ['acs.jpg', 'molecular.jpg', 'molecular_paper.jpg'],
    title: 'Unsupervised Learning of Molecular Embeddings',
    description: 'Enhanced clustering and property detection in compounds using tanimoto molecular fingerprinting and contrastive learning.',
    paperLink: 'https://www.youtube.com/watch?v=i5OJwdvQM6w',
  },
  {
    collageImages: ['IEEE-Logo.jpg', 'priors.jpg', 'priors_paper.jpg'],
    title: 'AuPPLE: Augmenting Physical Priors',
    description: 'Fine-tuning physical priors into large language models.',
    paperLink: 'https://www.youtube.com/watch?v=9QylugLT6eA&t=394s',
  },
  {
    collageImages: ['arxiv.jpg', 'galaxies.jpg', 'galaxies_paper.jpg'],
    title: 'GANaxy: Galaxy Image Anomaly Detection',
    description: 'Using contrastive learning to achieve state of the art accuracies in galaxy anomaly detection.',
    paperLink: 'https://www.youtube.com/watch?v=6GR1EVvtbeY',
  },
  {
    collageImages: ['IEEE-Logo.jpg', 'scale.jpg', 'scale_paper.jpg'],
    title: 'SCALE: Semantic Code Analysis via Learned Embeddings',
    description: 'Achieving code isomorphism via contrastive learning and parameter-efficient fine-tuning.',
    paperLink: 'https://www.youtube.com/watch?v=HwN21tFi9jI',
  },
  {
    collageImages: ['arxiv.jpg', 'underwater.jpg', 'underwater_paper.jpg'],
    title: 'Image to Image Diffusion For Underwater Image Enhancement',
    description: 'Designing an image-to-image pipeline with CLIP for efficient domain transfer in latent diffusion models.',
    paperLink: 'https://www.youtube.com/watch?v=ErFpe0GLUm8',
  },
];

export const researchHighlights = data2.map((item, index) => ({
  id: `highlight-${index}`,
  collageImages: item.collageImages.map(image => require(`./../../assets/images/${image}`)),
  title: item.title,
  description: item.description,
  bullets: item.bullets,
  paperLink: item.paperLink === 'pending' ? "https://google.com" : item.paperLink,
}));

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow`}
      style={{ ...style }}
      // style={{ ...style, display: "block", color: "gray"}}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow`}
      // style={{ ...style, display: "block", background: "red" }}

      style={{ ...style }}
      onClick={onClick}
    />
  );
};

export default function ResearchHighlightsCarousel() {
  var settings = {
    // dots: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,  
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          centerMode: false,
        }
      }
    ]
  };
  
  return (
    <div className="container mx-auto px-2 sm:px-4 lg:px-8 pt-4 sm:pt-8">
      <div className="mb-4 sm:mb-8">
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-2 sm:mb-4">Research Highlights</h2>
        <p className="text-base sm:text-lg text-center mb-4 sm:mb-6">Explore the latest research projects from our students.</p>
      </div>
      <Slider {...settings}>
        {researchHighlights.map((highlight, index) => (
          <div key={highlight.id} className="carousel-slide px-2">
            <div className="collage-container">
              <div className="collage">
                <div className="left-panel">
                  <div className="small-image">
                    <Image src={highlight.collageImages[0]} alt="Small Image 1" />
                  </div>
                  <div className="small-image">
                    <Image src={highlight.collageImages[1]} alt="Small Image 2" />
                  </div>
                </div>
                <div className="right-panel">
                  <div className="large-image">
                    <Image src={highlight.collageImages[2]} alt="Large Image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-container">
              <h3 className="title text-base sm:text-lg">{highlight.title}</h3>
              <p className="abstract text-sm sm:text-base">{highlight.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
