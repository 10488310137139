import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired
}

const defaultProps = {
  children: null,
  active: false,
  title: ''
}

const AccordionItem = ({
  className,
  children,
  active,
  title,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const content = useRef(null);

  useEffect(() => {
    active && openItem();
  }, [active]);

  const openItem = () => {
    content.current.style.maxHeight = content.current.scrollHeight + 'px';
    setIsActive(true);
  }

  const closeItem = () => {
    content.current.style.maxHeight = null;
    setIsActive(false);
  }

  const classes = classNames(
    isActive && 'is-active',
    className
  );

  return (
    <li {...props} className={classes}>
      <div
        className="accordion-header text-sm"
        onClick={isActive ? closeItem : openItem}
      >
        <span className="h6 m-0 text-white">{title}</span>
        <div className="accordion-icon"></div>
      </div>
      <div
        ref={content}
        className="accordion-content text-xs text-white"
      >
        <p>{children}</p>
      </div>
    </li>
  );
}

AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = defaultProps;

export default AccordionItem;