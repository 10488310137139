import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};

class ProgramOfferings extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const heroBackground = document.querySelector('.program-offering-background');
    const offset = window.pageYOffset;
    if (heroBackground) {
      heroBackground.style.backgroundPositionY = `${offset * -.5}px`;
    }
  }


  render() {
    // Destructure props and state
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    // Class name logic
    const outerClasses = classNames(
      'program offering section',
      // no background if on mobile
      window.innerWidth > 768 && 'program-offering-background',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap', 'pb-7',
      pushLeft && 'push-left'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="pt-1 container">
          <div className={innerClasses}>
            <div className="mb-8 invert-color">
              <h2 className="text-3xl font-bold text-center mb-4">Program Offerings</h2>
              <p className="text-lg text-center mb-6">The following programs will be offered during the summer of 2025.</p>
            </div>
            <div className={tilesClasses}>
              <div className="tiles-item-large reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-image center-content pb-2">
                    <Image
                      src={require('./../../assets/images/LaunchpadV1.jpg')}
                      alt="launchpad"
                      width={250}
                      height={250} />
                  </div>
                  <div className="testimonial-item-header text-center py-3">
                    <span className="testimonial-item-link fw-700 text-lg">
                        <a href="/launchpad">Launchpad Program</a>
                    </span>
                    <p className="text-sm mb-0 fw-600">
                      2 weeks of project-based learning
                    </p>
                  </div>
                  <div className="testimonial-item-content pb-3">
                    <p className="text-sm mb-0">
                    Build your own ChatGPT, racing game AI, protein folding simulator, or stock price forecaster! This program will cover the fundamentals of classical ML and deep learning, covering topics from regression to transformers to PPO. During the second half of the program, you'll apply your knowledge to a personalized project.
                    </p>
                  </div>
                  <div className="center-content pt-2">
                    <Button tag="a" className="hero-button pt-2 center-content w-22" color="primary" href="/launchpad" wideMobile>
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
              <div className="tiles-item-large reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-image center-content pb-2">
                    <Image
                      src={require('./../../assets/images/ResearchLabV1.jpg')}
                      alt="launchpad"
                      width={250}
                      height={250} />
                  </div>
                  <div className="testimonial-item-header text-center py-3">
                    <span className="testimonial-item-link fw-700 text-lg">
                        <a href="/research">Research Program</a>
                    </span>
                    <p className="text-sm mb-0 fw-600">
                      June 16, 2025 - August 11, 2025
                    </p>
                  </div>
                  <div className="testimonial-item-content pb-3">
                    <p className="text-sm mb-0">
                      During the first two weeks, you will learn the fundamentals in a structured ML bootcamp. Afterward, you will conduct original AI research in a team of like-minded individuals under the guidance of experienced mentors. You will present at a program-wide symposium and may submit your paper to PhD-level conferences.
                    </p>
                  </div>
                  <div className="center-content pt-2">
                    <Button tag="a" className="hero-button pt-2 center-content w-22" color="primary" href="/research" wideMobile>
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>      
            </div>

            
          </div>
        </div>
      </section>
    );
  }
}

ProgramOfferings.propTypes = propTypes;
ProgramOfferings.defaultProps = defaultProps;

export default ProgramOfferings;