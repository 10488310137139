import React, { useEffect } from 'react';
import classNames from 'classnames';
import Button from '../components/elements/Button';
import Image from '../components/elements/Image';
// import loadGoogleTagScript from '../utils/googleTag';
class ProgramOfferings extends React.Component {
  
  componentDidMount () {
    window.gtag('config', 'AW-16448313685');
    window.gtag('event', 'conversion', {'send_to': 'AW-16448313685/nQcDCPHIgJEZENWylaM9'});
  };

  render() {
    // Destructure props and state
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    // Class name logic
    const outerClasses = classNames(
      'program offering section',
      'hero-background',
      'scrollable-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap', 'pb-7',
      pushLeft && 'push-left'
    );


  
    return (
      <section {...props} className={outerClasses}>
        <div className="pt-1 container">
          <div className={innerClasses}>
            <div className="mb-8 invert-color">
              <h2 className="text-3xl font-bold text-center mb-4">Which program are you applying to?</h2>
              <p className="text-lg text-center mb-6">The following programs will be offered during the summer of 2025.</p>
            </div>
            <div className={tilesClasses}>
              <div className="tiles-item-large reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-image center-content pb-2">
                    <Image
                      src={require('./../assets/images/LaunchpadV1.png')}
                      alt="launchpad"
                      width={250}
                      height={250} />
                  </div>
                  <div className="testimonial-item-header text-center py-3">
                    <span className="testimonial-item-link fw-700 text-lg">
                        <a href="/launchpad">Launchpad Program</a>
                    </span>
                    <p className="text-sm mb-0 fw-600">
                      2 weeks of project-based learning
                    </p>
                  </div>
                  <div className="center-content pt-2">
                    <Button tag="a" className="hero-button pt-2 center-content w-22" color="primary" href="/apply-launchpad" wideMobile>
                      Launchpad Application
                    </Button>
                  </div>
                </div>
              </div>
              <div className="tiles-item-large reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-image center-content pb-2">
                    <Image
                      src={require('./../assets/images/ResearchLabV1.jpg')}
                      alt="launchpad"
                      width={250}
                      height={250} />
                  </div>
                  <div className="testimonial-item-header text-center py-3">
                    <span className="testimonial-item-link fw-700 text-lg">
                        <a href="/research">Research Program</a>
                    </span>
                    <p className="text-sm mb-0 fw-600">
                      June 16, 2025 - August 11, 2025
                    </p>
                  </div>
                  <div className="center-content pt-2">
                  <p className="text-sm mb-0 fw-700">
                      Applications opening soon for Summer 2025
                    </p>
                  </div>
                </div>
              </div>      
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ProgramOfferings;